<template>
  <div class="flex items-center">
    {{ params.data.sets.length }}
  </div>
</template>

<script>
export default {

}
</script>
